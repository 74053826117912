<template>
  <div class="app-container">
    查看取消报名
    <el-table :data="tableData" border>
      <el-table-column prop="date" label="序号" min-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="报名队伍"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{scope.row.team_snap? JSON.parse(scope.row.team_snap).name:'-' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="申请时间"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{  scope.row.created_at }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="100"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            v-if="scope.row.status==1"
            @click="handleStatus(scope.row.id)"
          >
            通过
          </el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="address"
        label="净胜分"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.net_points }}
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      list: [],
      listQuery: {
        page: 1,
        limit: 10,
        id: null,
      },
      tableData: [],
    };
  },
  created() {
    this.listQuery.id = this.$route.query.id;
    this.getList();
  },
  methods: {
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/match/enrollCancelApplyLog",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.tableData = response.data.data;
        this.listLoading = false;
      });
    },
    handleStatus(val) {
      request({
        url: "/api/schoolend/match/enrollCancelApplyPass",
        method: "post",
        data: { id: val },
      }).then(() => {
        this.getList();
        this.$message({
          type: "success",
          message: "操作成功",
        });
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
